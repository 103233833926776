import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqEvents from "~/functions/useCiqEvents";
export default {
  __name: 'about',
  emits: ["showchart"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var _useCiqEvents = useCiqEvents(),
      fetchingCompanyTranscripts = _useCiqEvents.fetchingCompanyTranscripts,
      futureCompanyEvents = _useCiqEvents.futureCompanyEvents,
      pastCompanyEvents = _useCiqEvents.pastCompanyEvents;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    var _useBaseUtils = useBaseUtils(),
      sectionWrapperStyle = _useBaseUtils.sectionWrapperStyle,
      I18nFn = _useBaseUtils.I18nFn,
      darkActive = _useBaseUtils.darkActive;
    var toggleCompanyBio = ref(true);
    var headers = [{
      text: "",
      value: "data-table-expand"
    }, {
      text: "Event",
      value: "headline"
    }, {
      text: "Date",
      value: "dateEpoch"
    }];
    var containerStyle = computed(function () {
      return _objectSpread(_objectSpread({}, sectionWrapperStyle), {}, {
        "--border-bio-btn": darkActive.value ? "#3D3D3D" : "#D9D9D9",
        "border-radius": "var(--section-border-radius)"
      });
    });
    var error = computed(function () {
      return $store.state.ciq.quotesError;
    });
    var ticker = computed(function () {
      var _$store$state$ciq;
      return (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.ticker;
    });
    var toggleBio = function toggleBio() {
      toggleCompanyBio.value = !toggleCompanyBio.value;
    };
    onMounted(function () {
      emit("showchart");
    });
    return {
      __sfc: true,
      emit: emit,
      fetchingCompanyTranscripts: fetchingCompanyTranscripts,
      futureCompanyEvents: futureCompanyEvents,
      pastCompanyEvents: pastCompanyEvents,
      $store: $store,
      $vuetify: $vuetify,
      sectionWrapperStyle: sectionWrapperStyle,
      I18nFn: I18nFn,
      darkActive: darkActive,
      toggleCompanyBio: toggleCompanyBio,
      headers: headers,
      containerStyle: containerStyle,
      error: error,
      ticker: ticker,
      toggleBio: toggleBio
    };
  }
};