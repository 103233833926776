var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"mt-2",attrs:{"dense":"","justify":"center","align":"start"}},[(_setup.error)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("\n            "+_vm._s(_setup.I18nFn(
                `There was an error fetching price quotes and market cap data`
              ))+"\n          ")])],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"pa-6",style:(_setup.containerStyle),attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1",attrs:{"cols":"auto"}},[_c('h3',[_vm._v(_vm._s(`${_setup.I18nFn("About the Company")}`))])]),_vm._v(" "),_c('v-col',{class:{ 'pr-0': !_setup.$vuetify.breakpoint.smAndUp },attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"toggle-bio-btn",class:{
              'px-0': _setup.$vuetify.breakpoint.smAndUp,
            },attrs:{"outlined":_setup.$vuetify.breakpoint.smAndUp,"plain":!_setup.$vuetify.breakpoint.smAndUp,"small":"","color":"grey","min-width":"35"},on:{"click":_setup.toggleBio}},[_c('v-icon',[_vm._v("\n              "+_vm._s(_setup.toggleCompanyBio ? "mdi-chevron-up" : "mdi-chevron-down")+"\n            ")])],1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('p',{class:{
              'bio-body': true,
              'mb-0': true,
              cropped: !_setup.toggleCompanyBio,
            }},[_vm._v("\n            "+_vm._s(_setup.ticker.businessdescription)+"\n          ")])]),_vm._v(" "),_c('v-col',{staticClass:"meta",attrs:{"cols":"12","md":"4"}},[_c('span',{staticClass:"d-block mb-2"},[_vm._v("\n            "+_vm._s(_setup.I18nFn("Industry"))+":\n            "),_c('b',[_vm._v("\n              "+_vm._s(`${_setup.I18nFn(_setup.ticker.simpleindustrydescription)}`)+"\n            ")])]),_vm._v(" "),_c('a',{staticClass:"font-weight-bold d-inline-block page-url",class:{ 'grey--text': _setup.$vuetify.theme.dark },attrs:{"href":`http://${_setup.ticker.webpage}`,"target":"_blank","rel":"noopener"}},[_c('span',{},[_vm._v(_vm._s(_setup.ticker.webpage))]),_vm._v(" "),_c('v-icon',{staticClass:"font-weight-medium gray--text text--darken-4",attrs:{"small":"","color":_setup.darkActive ? '#9e9e9e' : '#333'}},[_vm._v("\n              mdi-open-in-new\n            ")])],1)])],1)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"mt-4",style:(_setup.containerStyle),attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[(_setup.fetchingCompanyTranscripts)?_c('v-col',{attrs:{"justify":"center","align":"center","cols":"12"}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1):[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h3',{staticClass:"text-center"},[_vm._v("Future Events")]),_vm._v(" "),_c('CiqEventsTable',{attrs:{"events":_setup.futureCompanyEvents,"columns":_setup.headers,"watchlist":false}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h3',{staticClass:"text-center"},[_vm._v("Past Events")]),_vm._v(" "),_c('CiqEventsTable',{attrs:{"events":_setup.pastCompanyEvents,"columns":_setup.headers,"watchlist":false}})],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }